export const DOCTYPE_PATH_PREFIXES = {
  nativePlant: '/native-plants/',
  season: '/season/',
  aboutPage: '/',
  landingPage: '',
  plantListPage: '/',
  pollinator: '/pollinator/',
}

export const CURRENT_MONTH_NUMBER = new Date(Date.now()).getMonth() + 1

export const MONTH_NAMES_MAP = new Map([
  [1, { fullName: 'January', abbreviation: 'Jan' }],
  [2, { fullName: 'February', abbreviation: 'Feb' }],
  [3, { fullName: 'March', abbreviation: 'Mar' }],
  [4, { fullName: 'April', abbreviation: 'Apr' }],
  [5, { fullName: 'May', abbreviation: 'May' }],
  [6, { fullName: 'June', abbreviation: 'Jun' }],
  [7, { fullName: 'July', abbreviation: 'Jul' }],
  [8, { fullName: 'August', abbreviation: 'Aug' }],
  [9, { fullName: 'September', abbreviation: 'Sep' }],
  [10, { fullName: 'October', abbreviation: 'Oct' }],
  [11, { fullName: 'November', abbreviation: 'Nov' }],
  [12, { fullName: 'December', abbreviation: 'Dec' }],
])

export const MONTH_OPTIONS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]

export const SEASONS = {
  SPRING: {
    SEASON_NAME: 'spring',
    SEASON_MONTHS: [3, 4, 5],
    ACCENT_COLOR_VAR: '--spring-accent',
    BG_COLOR_VAR: '--oe-green-300',
    BG_GRADIENT_VAR: '--spring-gradient',
  },
  SUMMER: {
    SEASON_NAME: 'summer',
    SEASON_MONTHS: [6, 7, 8],
    ACCENT_COLOR_VAR: '--summer-accent',
    BG_COLOR_VAR: '--summer-bg-color',
    BG_GRADIENT_VAR: '--summer-gradient',
  },
  FALL: {
    SEASON_NAME: 'fall',
    SEASON_MONTHS: [9, 10, 11],
    ACCENT_COLOR_VAR: '--fall-accent',
    BG_COLOR_VAR: '--fall-bg-color',
    BG_GRADIENT_VAR: '--fall-gradient',
  },
  WINTER: {
    SEASON_NAME: 'winter',
    SEASON_MONTHS: [12, 1, 2],
    ACCENT_COLOR_VAR: '--winter-accent',
    BG_COLOR_VAR: '  --oe-blue-dark-100',
    BG_GRADIENT_VAR: '--winter-gradient',
  },
}

export const PLANT_PAGE_SECTIONS = {
  images: 'IMAGE GALLERY',
  plantName: 'NAME',
  bloomText: 'BLOOM',
  pollinators: 'POLLINATORS',
  description: 'DESCRIPTION',
  growingNearbyText: 'PLANTS GROWING NEARBY',
  habitat: 'HABITAT',
  conservationStatus: 'CONSERVATION STATUS',
  tidbits: 'INTERESTING TIDBITS',
}

export const HABITAT_OPTIONS = [
  { label: 'Glade', value: 'Glade' },
  { label: 'Woodland', value: 'Woodland' },
  { label: 'Grassland/Prairie', value: 'Grassland/Prairie' },
  { label: 'Savannah', value: 'Savannah' },
  { label: 'Wetland', value: 'Wetland' },
]

export const FLOWER_COLOR_OPTIONS = [
  { label: 'White', value: 'white' },
  { label: 'Blue', value: 'blue' },
  { label: 'Purple', value: 'purple' },
  { label: 'Pink', value: 'pink' },
  { label: 'Red', value: 'red' },
  { label: 'Orange', value: 'orange' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Brown', value: 'brown' },
  { label: 'Green', value: 'green' },
]

export const COLORS = {
  'oe-white': '#FFFFFF',
  'oe-black': '#000000',
  'oe-green-100': '#F1F5E9',
  'oe-green-200': '#D5E0BE',
  'oe-green-300': '#C7D6A8',
  'oe-green-400': '#AAC17D',
  'oe-green-500': '#8EAC52',
  'oe-green-600': '#7C9748',
  'oe-green-700': '#596C33',
  'oe-green-800': '#475629',
  'oe-green-900': '#35411F',
  'oe-green-yellow-100': '#FAF9EB',
  'oe-green-yellow-200': '#F1F0CA',
  'oe-green-yellow-300': '#E3E29A',
  'oe-green-yellow-400': '#DEDC85',
  'oe-green-yellow-500': '#D3D05D',
  'oe-green-yellow-600': '#B3B031',
  'oe-green-yellow-700': '#959329',
  'oe-green-yellow-800': '#787520',
  'oe-green-yellow-900': '#5A5818',
  'oe-blue-green-light-100': '#E4EDEA',
  'oe-blue-green-light-200': '#D6E4DF',
  'oe-blue-green-light-300': '#CCDDD7',
  'oe-blue-green-light-400': '#A1C0B4',
  'oe-blue-green-light-500': '#93B7A9',
  'oe-blue-green-light-600': '#7AA795',
  'oe-blue-green-light-700': '#639581',
  'oe-blue-green-light-800': '#486D5E',
  'oe-blue-green-light-900': '#314A41',
  'oe-blue-green-dark-100': '#D4E2E7',
  'oe-blue-green-dark-200': '#AAC4CF',
  'oe-blue-green-dark-300': '#95B5C3',
  'oe-blue-green-dark-400': '#6A98AB',
  'oe-blue-green-dark-500': '#58889C',
  'oe-blue-green-dark-600': '#4D7789',
  'oe-blue-green-dark-700': '#426675',
  'oe-blue-green-dark-800': '#375562',
  'oe-blue-green-dark-900': '#2C444E',
  'oe-blue-dark-100': '#E0E6F2',
  'oe-blue-dark-200': '#C1CEE5',
  'oe-blue-dark-300': '#839DCB',
  'oe-blue-dark-400': '#5D7EBB',
  'oe-blue-dark-500': '#3C598F',
  'oe-blue-dark-600': '#293D62',
  'oe-blue-dark-700': '#243556',
  'oe-blue-dark-800': '#1A273F',
  'oe-blue-dark-900': '#152025',
  'oe-red-100': '#FDE5DB',
  'oe-red-200': '#F8B194',
  'oe-red-300': '#F69770',
  'oe-red-400': '#E64D0E',
  'oe-red-500': '#C2410C',
  'oe-red-600': '#AA390B',
  'oe-red-700': '#792908',
  'oe-red-800': '#612106',
  'oe-red-900': '#180802',
  'oe-pink-100': '#FAE8F1',
  'oe-pink-200': '#F4D1E3',
  'oe-pink-300': '#EAA3C8',
  'oe-pink-400': '#DF76AD',
  'oe-pink-500': '#D44891',
  'oe-pink-600': '#C92F80',
  'oe-pink-700': '#9E2564',
  'oe-pink-800': '#731B49',
  'oe-pink-900': '#390E24',
  'oe-presumed-extirpated': '#666666',
  'oe-possibly-extirpated': '#99928b',
  'oe-critically-imperiled': '#ed7b76',
  'oe-imperiled': '#f8ae80',
  'oe-vulnerable': '#f7d48b',
  'oe-apparently-secure': '#a6d8b8',
  'oe-secure': '#668bb3',

}

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ContextProviders.js");
;
import(/* webpackMode: "eager", webpackExports: ["DisableDraftMode"] */ "/vercel/path0/components/DisableDraftMode.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Nav.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\",\"900\"],\"style\":[\"normal\",\"italic\"],\"variable\":\"--font-playfair-display\",\"preload\":true}],\"variableName\":\"playfairDisplay\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"style\":[\"normal\",\"italic\"],\"variable\":\"--font-raleway\",\"preload\":true}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/global.css");
